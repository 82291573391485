.imageWrapper {
  margin: 15px;
  padding: 0;
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.5);
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &::before {
    background: linear-gradient(to right, fade_out(#fff, 1) 0%, fade_out(#fff, 0.7) 100%);
    content: '';
    display: block;
    height: 100%;
    left: -75%;
    position: absolute;
    top: 0;
    transform: skewX(-25deg);
    width: 50%;
    z-index: 2;
  }

  &:hover,
  &:focus {
    &::before {
      animation: shine 0.85s;
    }
  }

  @keyframes shine {
    100% {
      left: 125%;
    }
  }

  &:hover {
    box-shadow: 0 0 10px #2196f3;
    transition: all 0.5s ease;
    filter: contrast(125%);
  }
}
