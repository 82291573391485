@import 'styles/mixins.scss';

.creditReal {
    @include adaptive-font(14, 12);
}

@media (max-width: 576px) {
    .userInfo {
        .creditFun {
            font-size: 12px;
        }
    }
}
