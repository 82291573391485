.overlayFrameWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.56);
    z-index: 2;

    .overlayFrame {
        display: flex;
        flex-direction: column;
        width: 85vw;
        height: 85vh;

        .iframe {
            width: 85vw;
            height: 85vh;
            border: none;
            margin: auto;
            padding: 0;
            overflow: hidden;
        }

        .btnClose {
            width: 50px;
            height: 50px;
            align-self: flex-end;
            background: transparent;
            color: #ffffff;
            cursor: pointer;
        }

        .updateCreditBtn {
            align-self: flex-start;
            font-size: 12px;
            color: #fff;
            cursor: pointer;
        }
    }
}
