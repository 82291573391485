@import 'styles/mixins.scss';

.card {
    min-height: 450px;
    width: 450px;
    margin: 20px;
    padding: 0;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    z-index: 0;

    .topStripeHeader {
        height: 5px;
        width: 100%;
        background-color: #2196f3;
        position: absolute;
        z-index: 2;
    }

    .listLogInfo {
        .listLogInfoSubheader {
            display: flex;
            justify-content: space-between;

            .listLogInfoVesrsionName {
                color: #2196f3;
            }
        }

        .listLogItem {
            margin: 5px 0 5px 30px;
            list-style-type: disc;
            @include adaptive-font(16, 14);
            padding-right: 10px;
            word-break: break-word;
        }
    }

    .whiteCircleIcon {
        height: 23px;
        width: 23px;
        background-color: #fff;
        border-radius: 100%;
        position: absolute;
        bottom: 5%;
        right: 7%;
        z-index: 1;
        cursor: pointer;
    }
}

@media (max-width: 767px) {
    .card {
        margin: 20px 10px;
    }
}
