.passwordDialogTitle {
  text-align: center;
}

.passwordDialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.passwordForm {
  width: 200px;

  .fieldForm:not(:first-child) {
    margin-top: 10px;
  }

  .passwordInput {
    width: 100%;
  }

  .passwordDialogContent {
    margin-top: 30px;
    padding: 0;
  }
}

.buttonsGroup {
  margin-top: 20px;
}
