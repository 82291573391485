.loginForm {
  margin: 0 auto;
  width: 200px;
  padding: 10px;
  margin-top: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 5px;

  & > * {
    margin-top: 10px;
  }
}
