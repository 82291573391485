@import 'styles/mixins.scss';

.linkWrapper {
    font-weight: bold;
    margin-top: 5px;
    text-align: center;

    .link {
        @include adaptive-font(20, 16);
        text-decoration: none;
        color: #fff;
        position: relative;
    }

    .link:after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        bottom: -3px;
        width: 0;
        height: 2px;
        background-color: #fff;
        transition: width 0.5s;
    }

    .link:hover:after {
        width: 100%;
        left: 0;
    }
}
