.select {
    width: 200px;
}

@media (max-width: 1199px) {
    .select {
        width: 140px;
    }
}

@media (max-width: 576px) {
    .select {
        width: 100px;
    }
}

.userGameSettingsSelect {
    width: 220px;
}
