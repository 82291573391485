@import 'styles/mixins.scss';

.creditButton {
    padding: 0;
    margin: auto;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.btnGroup {
    min-width: 300px;
    display: flex;
    justify-content: space-around;

    .button {
        min-width: 80px;
        margin: 5px;
        @include adaptive-font(14, 12);
    }
}
