.formGroup {
    height: 90px;
    display: flex;
    justify-content: center;
    gap: 50px;
}

@media (max-width: 576px) {
    .formGroup {
        gap: 0;
    }
}
