@import 'styles/variables.scss';

.linksGroup {
    display: flex;
    margin-left: 5vw;
    margin-right: auto;
    justify-content: space-around;
    align-items: center;
    gap: 30px;
}

@media (max-width: 991px) {
    .linksGroup {
        margin: 0;
        gap: 0;
        align-items: center;
        flex-direction: column;
    }
}

@media (max-width: 767px) {
    .linksGroup {
        margin: 15px 0 15px 0;
    }
}

@media (max-width: 576px) {
    .linksGroup {
        position: fixed;
        background-color: $main-color;
        justify-content: flex-start;
        order: 1;
        gap: 30px;
        margin: 0;
        padding: 0;
        left: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        z-index: -1;
        transition: all 0.3s ease 0s;
        padding: 250px 0 0 0;

        &_active {
            left: 0;
        }
    }
}
