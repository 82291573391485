@import 'styles/mixins.scss';

.header {
    .iconBtn {
        &:hover {
            background-color: transparent;
        }
    }
    .logo {
        height: 70px;
        max-width: 230px;
    }
}

.userInfoAndButtonsGroup {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include adaptive-font(20, 20);
}

@media (max-width: 991px) {
    .logo {
        height: 50px;
    }
}

@media (max-width: 767px) {
    .header {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
    }

    .userInfoAndButtonsGroup {
        display: flex;
        justify-content: center;
        align-items: center;

        &_demoInterface {
            width: 200px;
            flex-direction: row;
            justify-content: space-between;
        }
    }
}

@media (max-width: 576px) {
    .header {
        margin: 0;

        .userInfoAndButtonsGroup {
            width: 300px;
            flex-direction: column;

            &_demoInterface {
                width: 200px;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }
}
